import { useState } from 'react';
import axios from "axios";
import moment from 'moment';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import { Button } from '@chakra-ui/react';
import InputField from "components/fields/InputField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface PayloadType {
    [key: string]: any;
}

const TransactionExportModal: React.FC<{
    setSwalProps: any;
    isOpen: boolean;
    onClose(): void;
}> = ({ setSwalProps, isOpen, onClose }) => {
    const [isOpenDatePanel, setIsOpenDatePanel] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [dateToExport, setDateToExport] = useState<Date>(null);
    const [receiverEmail, setReceiverEmail] = useState<string>("");
    const userRole = localStorage.getItem("userRole")?.toLowerCase();

    const isFormValid = receiverEmail !== "";

    const closeModal = async () => {
        setDateToExport(null);
        setReceiverEmail("");
        onClose();
    };

    const handleSubmit = async () => {
        try {
            setIsProcessing(true);

            const firstAndLastDateOfMonth = getFirstAndLastDateOfMonth(dateToExport);
            const formattedFromDate = moment(firstAndLastDateOfMonth.firstDate, 'DD/MM/YYYY').format('YYYY-MM-DD 00:00');
            const formattedToDate = moment(firstAndLastDateOfMonth.lastDate, 'DD/MM/YYYY').format('YYYY-MM-DD 23:59');
            const queryObject: PayloadType = {
                is_export: true,
                email: receiverEmail,
                from: formattedFromDate,
                to: formattedToDate,
                userRole: userRole
            };

            const queryString = Object.keys(queryObject)
                .map(
                    (key) =>
                        `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`
                )
                .join("&");

            await axios.get(process.env.REACT_APP_API_URL + `/api/transaction/get-all-transactions?${queryString}`)
                .then(function (response) {
                    if (response && response.data?.status === 'ok') {
                        closeModal();
                        setSwalProps({
                            show: true,
                            icon: "success",
                            title: "Success",
                            html: "Your request has been successfully submitted",
                            showConfirmButton: true,
                            didClose: () => {
                                setSwalProps({});
                                setIsProcessing(false);
                            },
                        });
                    } else {
                        setIsProcessing(false);
                    }
                })
                .catch(function (error) {
                    setIsProcessing(false);
                    setSwalProps({
                        show: true,
                        icon: "error",
                        title: "Oops!",
                        html: error.response.data.message,
                        showConfirmButton: true,
                        didClose: () => {
                            setSwalProps({});
                            setIsProcessing(false);
                        },
                    });
                });
        } catch (error: any) {
            setIsProcessing(false);
            setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: "Something went wrong, please try again later.",
                showConfirmButton: true,
                didClose: () => {
                    setSwalProps({});
                    setIsProcessing(false);
                },
            });
        }
    };

    const getFirstAndLastDateOfMonth = (date: Date) => {
        // First date of the month
        const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);

        // Last date of the month
        const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        return { firstDate, lastDate };
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                    <ModalBody className="!text-navy-700">
                        <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                            <h1 className="mb-[20px] text-2xl font-bold">Export Transaction</h1>
                            <div className="mb-[20px] text-sm">
                                <div className="flex justify-center"><hr className="mb-3 w-full" /></div>
                                <div className="mb-3 export-date-picker-wrapper">
                                    <label className="mb-1 block text-sm font-medium text-navy-700 dark:text-white">
                                        Month & year you'd like to export *
                                    </label>
                                    <DatePicker
                                        selected={dateToExport}
                                        onInputClick={() => setIsOpenDatePanel(!isOpenDatePanel)}                                        
                                        onSelect={() => setIsOpenDatePanel(false)}
                                        onChange={(date: any) => setDateToExport(date)}
                                        dateFormat="MMM, yyyy"
                                        placeholderText="Please select"
                                        showMonthYearPicker
                                        open={isOpenDatePanel}
                                        className="!rounded-md !shadow-sm !focus:outline-none !outline-none !cursor-pointer"
                                        onKeyDown={(e) => e.preventDefault()}
                                        readOnly={true}
                                        maxDate={new Date()}
                                    />
                                </div>
                                <InputField
                                    disabled={isProcessing}
                                    variant="auth"
                                    extra="mb-3 shadow-sm number-input"
                                    label="Please fill in email to receiver report *"
                                    placeholder=""
                                    id="receiver_email"
                                    type="text"
                                    autoComplete="off"
                                    value={receiverEmail}
                                    onChange={(e) => setReceiverEmail(e.target.value)}
                                />
                            </div>
                            <div className="flex gap-2 justify-end">
                                <Button
                                    onClick={() => closeModal()}
                                    isDisabled={isProcessing}
                                    className="btn btn-default"
                                >
                                    Close
                                </Button>
                                <Button
                                    onClick={() => handleSubmit()}
                                    isDisabled={!isFormValid}
                                    isLoading={isProcessing}
                                    loadingText='Processing...'
                                    className="btn btn-primary"
                                >
                                    Export
                                </Button>
                            </div>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default TransactionExportModal;
