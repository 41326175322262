/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate
} from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import useRoutes from "../../routes";
import axios from "axios";
import moment from "moment";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import { Button } from '@chakra-ui/react';
import { useIdleTimer } from 'react-idle-timer';

const defaultSessionExpireCountdownInSeconds = 60;
const defaultIdleTimeoutInMinute = 15;

export default function Layout(props: {
  [x: string]: any;
  userRole: any;
  userPermissions: any;
}) {
  const { userRole, userPermissions } = props;
  const { routes } = useRoutes(userPermissions);
  const roleRoutes = userRole ? routes[userRole.toLowerCase()] || [] : [];
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [currentRoute, setCurrentRoute] = useState("Dashboard");
  const [isIdleModalOpen, setIsIdleModalOpen] = useState(false);
  const idleTimerRef = useRef<any>(null);
  const [countdown, setCountdown] = useState(defaultSessionExpireCountdownInSeconds);
  const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const idleIntervalIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  useEffect(() => {
    const updateFavicon = (newFavicon32: string, newFavicon16: string) => {
      const icon32 = document.querySelector('link[rel="icon"][sizes="32x32"]');
      const icon16 = document.querySelector('link[rel="icon"][sizes="16x16"]');

      if (icon32) {
        icon32.remove();
      }
      if (icon16) {
        icon16.remove();
      }

      const newIcon32 = document.createElement('link');
      newIcon32.rel = 'icon';
      newIcon32.type = 'image/png';
      newIcon32.setAttribute('sizes', '32x32');
      newIcon32.href = `${newFavicon32}?t=${new Date().getTime()}`;

      const newIcon16 = document.createElement('link');
      newIcon16.rel = 'icon';
      newIcon16.type = 'image/png';
      newIcon16.setAttribute('sizes', '16x16');
      newIcon16.href = `${newFavicon16}?t=${new Date().getTime()}`;

      document.head.appendChild(newIcon32);
      document.head.appendChild(newIcon16);
    };

    updateFavicon('/favicon-32x32.png', '/favicon-16x16.png');
  }, []);

  useEffect(() => {
    if (roleRoutes.length > 0) {
      const pathnameSegments = location.pathname.split("/");
      const pathSegment = pathnameSegments[2];
      if (
        roleRoutes.filter((x: any) => x.path.includes(pathSegment)).length !== 0
      ) {
        getActiveRoute(roleRoutes);
      } else {
        navigate(`/${userRole.toLowerCase()}/default`);
      }
    }
  }, [location.pathname, roleRoutes]);

  const getActiveRoute = (
    routes: RoutesType[] | undefined
  ): string | boolean => {
    if (!routes || routes.length === 0) {
      return "Dashboard";
    }

    let activeRoute = "Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (
    routes: RoutesType[] | undefined
  ): string | boolean => {
    if (!routes || routes.length === 0) {
      return false;
    }

    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }

    return false;
  };

  const getRoutes = (routes: RoutesType[] | undefined): any => {
    if (!routes || routes.length === 0) {
      return null;
    }

    return routes.map((prop, key) => {
      return (
        <Route
          path={`/${prop.layout}/${prop.path}`}
          element={prop.component}
          key={key}
        />
      );
    });
  };

  const cleanupBeforeSignedOut = () => {
    setCountdown(defaultSessionExpireCountdownInSeconds);
    clearInterval(idleIntervalIdRef.current);
  }

  const navigateToSignin = () => {
    cleanupBeforeSignedOut();
    navigate(`/auth/sign-in`);
  };

  const navigateToSigninCauseOfIdle = () => {
    cleanupBeforeSignedOut();
    const link = document.createElement('a');
    link.href = '/auth/sign-in?idle=true';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const startCountdown = () => {
    const targetTime = Date.now() + defaultSessionExpireCountdownInSeconds * 1000;
    let remainingTime = defaultSessionExpireCountdownInSeconds;

    const id = setInterval(() => {
      remainingTime = Math.max(0, Math.round((targetTime - Date.now()) / 1000));

      if (remainingTime <= 0) {
        clearInterval(id);
        setCountdown(0);
        navigateToSigninCauseOfIdle();
      }

      setCountdown(remainingTime);
    }, 1000);

    localStorage.setItem('startIdleCheckId', String(id));
    idleIntervalIdRef.current = id;
  };

  const handleOnIdle = () => {
    setIsIdleModalOpen(true);
    setCountdown(60);
  };

  const handleOnActive = () => {
    console.log('User noticed');
  };

  useIdleTimer({
    timeout: 1000 * 60 * defaultIdleTimeoutInMinute,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500,
    events: ["click"]
  });

  useEffect(() => {
    if (isIdleModalOpen) {
      startCountdown();
    }

    return () => {
      clearInterval(countdownIntervalRef.current!);
      clearInterval(idleIntervalIdRef.current!);
      localStorage.removeItem('startIdleCheckId');
    };
  }, [isIdleModalOpen]); // eslint-disable-line

  const checkTokenExpires = () => {
    const apiTokenExpiresFromStorage = localStorage.getItem("apiTokenExpires");
    const apiTokenExpires = moment(apiTokenExpiresFromStorage);
    const currentTime = moment();
    const differenceInSeconds = apiTokenExpires.diff(currentTime, 'seconds');
    if (differenceInSeconds <= 3600) {
      return true;
    } else {
      return false;
    }
  };  

  const extendTokenExpires = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/user/extend-auth`,
      {}
    );

    let { token, expires } = response.data;

    if (token != null) {
      localStorage.setItem("apiToken", token);
      localStorage.setItem("apiTokenExpires", expires);
    } else {
      navigateToSignin();
    }
  }

  const handleStaySignedIn = () => {
    setIsIdleModalOpen(false);
    clearInterval(countdownIntervalRef.current!);
    idleTimerRef.current?.reset();

    if (checkTokenExpires()) {
      extendTokenExpires();
    }
  };

  document.documentElement.dir = "ltr";

  return (
    routes && (
      <>
        <Modal isOpen={isIdleModalOpen} closeOnOverlayClick={false} onClose={() => { }}>
          <ModalOverlay id="extend-modal-overlay" className="bg-[#000] !opacity-30" />
          <ModalContent className="!z-[1002] !m-auto !w-max !min-w-[680px] !max-w-[85%] md:top-[12vh]">
            <ModalBody className="!text-navy-700">
              <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[680px] flex flex-col !z-[1004]">
                <h1 className="mb-[20px] text-2xl font-bold">Inactivity Alert</h1>
                <div className="mb-[20px]">
                  You will be signed out in <strong>{countdown >= 0 ? countdown : '0'} seconds</strong> due to inactivity. To stay signed in, please click OK.
                </div>
                <div className="flex gap-2 justify-end">
                  <Button
                    onClick={() => handleStaySignedIn()}
                    className="rounded-xl bg-green-500 px-3 py-2.5 text-sm font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200">
                    OK
                  </Button>
                </div>
              </Card>
            </ModalBody>
          </ModalContent>
        </Modal>

        <div className="flex h-full w-full">
          <Sidebar
            open={open}
            onClose={() => setOpen(false)}
            userRole={userRole}
            userPermissions={userPermissions}
          />
          <div className="h-full w-full bg-lightPrimary">
            <main className="mx-[24px] h-full flex-none transition-all md:pr-2 xl:ml-[347px]">
              <div className="h-full">
                <Navbar
                  isIdle={isIdleModalOpen}
                  onOpenSidenav={() => setOpen(!open)}
                  brandText={currentRoute}
                  secondary={getActiveNavbar(roleRoutes)}
                />
                <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
                  <Routes>
                    {getRoutes(roleRoutes)}
                    <Route
                      path="/"
                      element={
                        <Navigate
                          to={`/${userRole?.toLowerCase() || ""}/default`}
                          replace
                        />
                      }
                    />
                  </Routes>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    )
  );
}
